<template>
    <div id="org_manage">
        <jy-query ref="form" :model="form">
            <jy-query-item prop="orgNa" label="机构名称:">
                <el-input placeholder="请输入" v-model="form.orgNa"></el-input>
            </jy-query-item>
            <jy-query-item prop="pOrgNa" label="上级机构:">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.pOrgNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item prop="orgType" label="机构类型:" v-if="visible">
                <el-select placeholder="请选择" v-model="form.orgType">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="环服组织" value="1"></el-option>
                    <el-option label="监管组织" value="2"></el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('wastesSystemSettingsOrganizationManagementList')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('wastesSystemSettingsOrganizationManagementReset')">重置</el-button>
                <el-row>
                    <el-button type="primary" v-on:click="addUpdate()" v-if="btnexist('wastesSystemSettingsOrganizationManagementInsert')">新增</el-button>
                </el-row>
            </template>
        </jy-query>
        <jy-table :data="dataList" @select="selectchange" @select-all="selectallchange" v-loading="loading">
            <jy-table-column type="index" label="序号" width="60" fixed="left"></jy-table-column>
            <jy-table-column prop="orgNa" label="机构名称" fixed="left" min-width="100"></jy-table-column>
            <jy-table-column prop="pOrgNa" label="上级机构" fixed="left" min-width="100"></jy-table-column>
            <jy-table-column label="机构类型" min-width="100">
                <template slot-scope="scope">
                    {{ scope.row.orgType | orgtype }}
                </template>
            </jy-table-column>
            <jy-table-column label="所属行政区域" prop="regionNa"></jy-table-column>
            <jy-table-column label="企业logo">
                <template v-slot="scope">
                    <span v-if="!scope.row.logo">-</span>
                    <el-image v-else fit="contain" class="logo_img" :src="scope.row.logo" />
                </template>
            </jy-table-column>
            <jy-table-column label="备注" prop="remark"></jy-table-column>
            <jy-table-column label="创建人" prop="cUserNa"></jy-table-column>
            <jy-table-column prop="createT" label="创建日期" min-width="160"></jy-table-column>
            <jy-operate v-bind:list="operateList" width="160"></jy-operate>
        </jy-table>
        <jy-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-size="pageSize"
            :total="total"
        ></jy-pagination>
        <add-update-org ref="addUpdateOrg" @updateList="onupdate"></add-update-org>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择上级机构"></institutions-tree>
        <region-tree></region-tree>
    </div>
</template>
<script>
    import { mapMutations } from "vuex";
    import addUpdateOrg from "@/components/pages/admin/systemSettings/organizationManagement/addUpdateOrg";
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    import regionTree from "../../../components/pages/admin/common/dialog-tree/region_tree.vue";
    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        data() {
            return {
                statusList: [],
                form: {
                    orgNa: "",
                    pOrgId: "",
                    pOrgNa: "",
                    orgType: ""
                },
                pageSize: 10,
                total: 100,
                pageIndex: 1,
                dataList: [],
                operateList: [],
                // 批量删除
                batchdeleteList: [],
                loading:false,

                btnMenuId: "wastesSystemSettingsOrganizationManagement"
            };
        },
        mixins: [btnMixins],
        components: {
            addUpdateOrg,
            institutionsTree,
            regionTree
        },
        filters: {
            orgtype(val) {
                if (val == "1") {
                    return "环服组织";
                } else if (val == "2") {
                    return "监管组织";
                } else {
                    return "";
                }
            }
        },
        created() {
            this.initOrgId();
            this.getList();
            this.setOperateList();
        },
        computed: {
            visible() {
                const { roleId } = this.$store.state.info;
                return roleId === 'admin';
            },
        },
        // watch: {
        //     'form.orgType': {
        //         handler(newValue) {
        //             this.form.orgNa = this.$options.filters['orgtype'](newValue);
        //         }
        //     }
        // },
        methods: {
            ...mapMutations(["resetTree"]),
            setOperateList() {
                let l = [
                    {
                        name: "修改",
                        icon: "el-icon-edit-outline",
                        fun: this.addUpdate,
                        isShow: () => {
                            return this.btnexist("wastesSystemSettingsOrganizationManagementUpdate");
                        }
                    },
                    {
                        name: "删除",
                        icon: "el-icon-delete",
                        fun: this.handleDelete,
                        isShow: () => {
                            return this.btnexist("wastesSystemSettingsOrganizationManagementDelete");
                        }
                    }
                    // {
                    //     name: '查看',
                    //     icon: 'el-icon-view',
                    //     fun: this.handlecheck
                    // }
                ];
                this.operateList = l;
            },
            initOrgId() {
                const { uType = "" } = this.$store.state.info;
                this.form.orgType = uType.toString();
                //this.form.orgNa = this.$options.filters['orgtype'](this.form.orgType);
            },
            getList() {
                let option = {
                    // ...this.form,
                    sysOrg: {
                        pOrgId: this.form.pOrgId,
                        orgNa: this.form.orgNa
                    },
                    sysOrgEx: {
                        orgType: this.form.orgType
                    },
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                };
                let url = "/sys/org/pageQuery";
                // console.log(option)
                this.loading = true
                this.$http.post(url, option).then(({ detail }) => {
                    this.loading = false
                    detail.list.forEach(item => {
                        item.createT = this.$util.formatTime(item.createT);
                    });
                    this.dataList = detail.list;
                    this.total = detail.total;
                },()=>{
                    this.loading = false
                });
            },
            // 查询表格
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            // 更新表格
            onupdate() {
                this.getList();
                // this.$refs.institutions.getjgAllList();
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.pOrgId = "";
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            // 上级机构查询
            addInstitutions(data) {
                this.form.pOrgId = data.orgId;
                this.form.pOrgNa = data.orgNa;
            },
            addUpdate(option) {
                if (option) {
                    this.$refs.addUpdateOrg.init(option, "update");
                } else {
                    this.$refs.addUpdateOrg.init();
                }
            },
            // 批量选择
            selectchange(selection) {
                // console.log(selection)
                this.batchdeleteList = selection;
            },
            selectallchange(selection) {
                // console.log(selection)
                this.batchdeleteList = selection;
            },
            // 批量删除
            // handleBatchDelete() {
            //     let option = []
            //     this.batchdeleteList.forEach(item => {
            //         option.push(item.orgId)
            //     })
            //     let url = '/system/org/batchDeleteOrg'
            //     let o = {
            //         ordIds: option
            //     }
            //     this.$http.post(url, o).then(res => {
            //         this.$message({
            //             type: 'success',
            //             message: res.msg
            //         })
            //         this.getList()
            //     })
            // },
            // 删除
            handleDelete(row) {
                this.$confirm("是否删除该机构?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        let url = "/sys/org/delete";
                        this.$http.post(url, row.orgId, { isRequestParam: false }).then(res => {
                            this.$message({
                                type: "success",
                                message: res.msg
                            });
                            this.resetTree();
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            }
        }
    };
</script>
<style scoped="organizationManagement" lang="scss">
    #org_manage {
        .logo_img {
            width: 60px;
            height: 60px;

            i {
                font-size: 60px;
            }
        }
    }
</style>
