<template>
    <div class="add_update_org">
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :rules="rules" :label-width="formLabelWidth">
                    <el-form-item label="机构名称:" prop="orgNa">
                        <el-input v-model="diaform.orgNa"></el-input>
                    </el-form-item>
                    <el-form-item label="机构类型:" prop="orgType" :label-width="formLabelWidth">
                        <el-radio v-model="diaform.orgType" label="1" v-if="!uType || uType === 1">环服组织</el-radio>
                        <el-radio v-model="diaform.orgType" label="2" v-if="!uType || uType === 2">监管组织</el-radio>
                    </el-form-item>
                    <el-form-item label="上级机构:" prop="pOrgNa">
                        <div @click="selectInstitutions">
                            <el-input placeholder="请选择" v-model="diaform.pOrgNa">
                                <el-button slot="append" icon="el-icon-circle-close" @click.stop="clear"></el-button>
                            </el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="所属行政区域:" prop="regionId">
                        <div @click="selectRegion">
                            <el-input placeholder="请选择" v-model="diaform.regionNa">
                                <el-button slot="append" icon="el-icon-circle-close" @click.stop="clearRegion"></el-button>
                            </el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="企业logo" prop="logo">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleLogoUrlAvatarSuccess"
                            :on-error="imgUplodError"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                            v-loading="loading"
                        >
                            <img v-if="diaform.logo" :src="diaform.logo" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="备注:" prop="remark">
                        <el-input v-model="diaform.remark"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
        <institutions-tree refresh :showType="showType" ref="institutions" @addInstitutions="addInstitutions" title="选择上级机构"></institutions-tree>
        <region-tree ref="region" @select="addRegion"></region-tree>
    </div>
</template>
<script>
    import { mapMutations } from "vuex";
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    import regionTree from "../../common/dialog-tree/region_tree.vue";
    export default {
        data() {
            return {
                uType: null,
                type: "",
                title: "",
                diaform: {
                    orgNa: "",
                    pOrgNa: "",
                    pOrgId: "",
                    orgType: "1",
                    regionId: "",
                    regionNa: "",
                    logo: "",
                    remark: "",
                },
                rules: {
                    orgNa: [
                        {
                            required: true,
                            message: "请输入机构名称",
                            trigger: "blur"
                        }
                    ],
                    orgType: [
                        {
                            required: true,
                            message: "请选择机构类型",
                            trigger: "blur"
                        }
                    ],
                    regionId: [
                        {
                            required: true,
                            message: "请选择所属行政区域",
                            trigger: "change"
                        }
                    ]
                },
                dialogVisible: false,
                formLabelWidth: "120px",

                uploadImgUrl: "/iwastes-admin/sys/user/uploadLogo",
                uploadHeader: {},
                loading:false
            };
        },
        computed: {
            showType() {
                if (this.diaform.orgType === "1") {
                    return [1];
                } else {
                    return [2];
                }
            }
        },
        activated() {},
        components: {
            institutionsTree,
            regionTree
        },
        watch: {
            type: function (val) {
                let t = "";
                switch (val) {
                    case "add":
                        t = "新增机构";
                        break;
                    case "update":
                        t = "修改机构";
                        break;
                    default:
                        t = "新增机构";
                }
                this.title = t;
            },
            dialogVisible(newview) {
                if (!newview) {
                    this.resetForm("dialogForm");
                }
            }
        },
        created() {
            this.uType = this.$store.state.info.uType;
            if (this.uType) {
                this.diaform.orgType = this.uType.toString();
            }
            this.setUploadHeader();
        },
        methods: {
            imgUplodError(){
                this.loading = false
            },
            handleLogoUrlAvatarSuccess({ detail }) {
                this.loading = false
                this.diaform.logo = detail;
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg" || file.type === "image/png";

                if (!isJPG) {
                    this.$message.error("图片只能是JPG或PNG格式");
                }else{
                    this.loading=true
                }
                return isJPG;
            },
            setUploadHeader() {
                let header = {
                    token: this.$util.getStor("token")
                };
                this.uploadHeader = header;
            },
            ...mapMutations(["resetTree"]),
            init(option, type) {
                this.type = type ? type : "add";
                this.dialogVisible = true;
                if (option) {
                    this.$nextTick(() => {
                        Object.keys(option).forEach(key => {
                            if (option[key] !== undefined) {
                                this.diaform[key] = option[key];
                            }
                        });
                    });
                }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.diaform.pOrgId = "0";
                this.diaform.regionNa = "";
            },
            cancel() {
                this.dialogVisible = false;
                console.log("cancel");
            },
            // 选择机构树
            selectInstitutions() {
                if (this.diaform.orgType) {
                    this.$refs.institutions.init();
                } else {
                    this.$message.warning({
                        message: "请先选择机构类型"
                    });
                }
            },
            selectRegion() {
                this.$refs.region.init();
            },
            // 上级机构查询
            addInstitutions(data) {
                console.log(data);
                this.diaform.pOrgId = data.orgId;
                this.diaform.pOrgNa = data.orgNa;
            },
            addRegion(data) {
                console.log(data);
                this.diaform.regionId = data.regionId;
                this.diaform.regionNa = data.regionNa;
                console.log(this.diaform);
            },
            clear() {
                this.diaform.pOrgId = "0";
                this.diaform.pOrgNa = "";
            },
            clearRegion() {
                this.diaform.regionId = "";
                this.diaform.regionNa = "";
            },
            save() {
                this.$refs.dialogForm.validate(valid => {
                    if (valid) {
                        let url = "/sys/org/";
                        if (this.type == "add") {
                            url = url + "add";
                            this.diaform.type = "0";
                        } else {
                            url = url + "update";
                        }
                        let option = JSON.parse(JSON.stringify(this.diaform));
                        this.$http.post(url, option).then(res => {
                            this.$message({
                                type: "success",
                                message: res.msg
                            });
                            this.resetTree();
                            this.dialogVisible = false;
                            this.$emit("updateList");
                        });
                    } else {
                        return false;
                    }
                });
            }
        }
    };
</script>
